const FMP_SIT_URL = 'https://chorus-fault-management-sit.demo.outwide.net'
const FMP_PPE_URL = 'https://chorus-fault-management-ppe.demo.outwide.net'
const FMP_PROD_URL = 'https://faultmanagement.chorus.co.nz'

const FIELDWORK_DEV_DOMAIN = 'https://dev-fieldwork.chorus.co.nz'
const FIELDWORK_SIT_DOMAIN = 'https://sit-fieldwork.chorus.co.nz'
const FIELDWORK_PPE_DOMAIN = 'https://ppe-fieldwork.chorus.co.nz'
const FIELDWORK_PROD_DOMAIN = 'https://fieldwork.chorus.co.nz'

const URL = window.location.href

let fmpUrl = FMP_SIT_URL
// Get current environment name
let environment = 'LOCAL'

if (URL.includes(FIELDWORK_PROD_DOMAIN)) {
  environment = 'PROD'
  fmpUrl = FMP_PROD_URL
} else if (URL.includes(FIELDWORK_PPE_DOMAIN)) {
  environment = 'PPE'
  fmpUrl = FMP_PPE_URL
} else if (URL.includes(FIELDWORK_SIT_DOMAIN)) {
  environment = 'SIT'
} else if (URL.includes(FIELDWORK_DEV_DOMAIN)) {
  environment = 'DEV'
}

export const ENV = environment

export const FMP_URL = fmpUrl

export const AZURE_AUTH_ENABLED = true

let clientID = ''
let scopes = [] as string[]
let camsApiBaseUrl = ''
let fmpApiBaseUrl = ''

// to use without JWT
let basicAuthUsername = 'ed07a0e6a1d34fe69dc88e9cd8dc9b68'
let basicAuthSecret = 'C845B294ac0346bE81ABeCAb0349ccF1'

if (AZURE_AUTH_ENABLED) {
  // to use with JWT
  switch (ENV) {
    case 'PROD':
      basicAuthUsername = 'ff80bbc26c3b4f4087730543ddc6fdf1'
      basicAuthSecret = 'C793f87b22714eECA3b2fCdCdF4f9A84'
      break

    case 'PPE':
      basicAuthUsername = 'a63a0e03753643249f7892df74e86d47'
      basicAuthSecret = 'Bc41e83377934d06BBc83De3Cf6F5bb0'
      break

    default:
      // SIT and DEV
      basicAuthUsername = '61e64ea9ace14bd6a9677c0b04a78423'
      basicAuthSecret = 'f4f7Fff2EFcf4c89A4C8A294821f5B37'
  }
}

scopes = [`${window.location.protocol}//${window.location.hostname}/FieldWork.All`]

switch (ENV) {
  case 'PROD':
    clientID = 'a37629f2-2c9f-49e7-9c45-f3aaea5fbadd'
    camsApiBaseUrl = 'https://api.chorus.co.nz'
    fmpApiBaseUrl = 'https://api.chorus.co.nz/fre/v1'
    break

  case 'PPE':
    clientID = '9eb3583c-dd14-4dd2-8852-05b642d53664'
    camsApiBaseUrl = 'https://api.ppe.chorus.co.nz'
    fmpApiBaseUrl = 'https://api.ppe.chorus.co.nz/fre/v1'
    break

  case 'SIT':
    clientID = 'c0b217e8-b35e-4a11-bdad-a9d7d8b10f2a'
    camsApiBaseUrl = 'https://cloudhub.sit.chorus.co.nz'
    fmpApiBaseUrl = 'https://cloudhub.sit.chorus.co.nz/fre/v1'
    break

  default:
    // same as SIT
    clientID = 'c0b217e8-b35e-4a11-bdad-a9d7d8b10f2a'
    camsApiBaseUrl = 'https://cloudhub.sit.chorus.co.nz'
    fmpApiBaseUrl = 'https://cloudhub.sit.chorus.co.nz/fre/v1'
    break
}

export const AZURE_CLIENT_ID = clientID
export const AZURE_TENANT_ID = '41360c96-1755-4bf8-8527-81bb0ad2ac43'
export const AZURE_SCOPES = scopes
export const CAMS_API_BASE_URL = camsApiBaseUrl
export const FMP_API_BASE_URL = fmpApiBaseUrl
export const BASIC_AUTH_USER = basicAuthUsername
export const BASIC_AUTH_SECRET = basicAuthSecret

let WRM_URL = 'https://chorus-workrequestmanager-dev.demo.outwide.net' // DEV
switch (environment) {
  case 'PROD':
    WRM_URL = 'https://workrequestmanager.chorus.co.nz'
    break

  case 'PPE':
    WRM_URL = 'https://chorus-workrequestmanager-ppe.demo.outwide.net'
    break

  case 'SIT':
    WRM_URL = 'https://chorus-workrequestmanager-sit.demo.outwide.net'
    break
}

export const WRM_BASE_URL = WRM_URL
