import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { nextTick } from 'vue'
import { SMRoutes } from '@/router/SMRoutes'
import { FMPRoutes } from '@/router/FMPRoutes'
import { FWRoutes } from '@/router/FWRoutes'
import { CMRoutes } from '@/router/CMRoutes'

const routes: Array<RouteRecordRaw> = [
  ...FWRoutes,
  ...FMPRoutes,
  ...SMRoutes,
  ...CMRoutes
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = to.meta.title as string || 'Fault Management | Field Work'
    const theme = to.meta.theme as string || 'FW'
    document.body.className = theme

    setTimeout(() => {
      const ev = new CustomEvent('routeChanged', {
        detail: {
          to,
          from
        }
      })
      document.dispatchEvent(ev)
    }, 100)
  })
})

export default router
