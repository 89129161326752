import { type RouteRecordRaw } from 'vue-router'

const basePath = '/fault'
export const FMPRoutes: Array<RouteRecordRaw> = [
  {
    path: basePath,
    name: 'FMP-Home',
    component: () => import('@/pages/FMP/Home.vue'),
    meta: {
      layout: 'FMP',
      header: 'Fault Management',
      theme: 'FMP'
    }
  },
  {
    path: `${basePath}/create-fre`,
    name: 'FMP-CreateFRE',
    component: () => import('@/pages/FMP/CreateFRE.vue'),
    meta: {
      layout: 'FMP',
      header: 'Fault Management',
      theme: 'FMP'
    }
  },
  {
    path: `${basePath}/create-misc-ticket`,
    name: 'FMP-CreateMiscTicket',
    component: () => import('@/pages/FMP/CreateMiscTicket.vue'),
    meta: {
      layout: 'FMP',
      header: 'Fault Management',
      theme: 'FMP'
    }
  },
  {
    path: `${basePath}/search-tickets`,
    name: 'FMP-SearchPage',
    component: () => import('@/pages/FMP/SearchPage.vue'),
    meta: {
      layout: 'FMP',
      header: 'Fault Management',
      theme: 'FMP'
    }
  },
  {
    path: `${basePath}/fre/:id`,
    name: 'FMP-ViewFRE',
    component: () => import('@/pages/FMP/ViewFRE.vue'),
    meta: {
      layout: 'FMP',
      header: 'Fault Management',
      theme: 'FMP'
    }
  },
  {
    path: `${basePath}/misc-ticket/:id`,
    name: 'FMP-ViewMiscTicket',
    component: () => import('@/pages/FMP/ViewMiscTicket.vue'),
    meta: {
      layout: 'FMP',
      header: 'Fault Management',
      theme: 'FMP'
    }
  }

]

export function isFMPRoute (routeName: string | symbol): boolean {
  return FMPRoutes.some((route) => route.name === routeName.toString())
}
