import { type RouteRecordRaw } from 'vue-router'

const basePath = '/fieldwork'
export const FWRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'FW-Home',
    component: () => import('@/pages/FW/FieldWorkHome.vue'),
    meta: { title: 'Field Work', layout: 'FW', header: 'Field Work' }
  },
  {
    path: `${basePath}/styleguide`,
    name: 'FW-StyleGuide',
    component: () => import('@/pages/FW/StyleGuide.vue'),
    meta: { title: 'Style guide | Field Work', layout: 'FW' }
  },
  {
    path: '/login',
    name: 'FW-Login',
    component: () => import('@/pages/FW/LoginPageFieldWork.vue'),
    meta: {
      title: 'Log in | Field work',
      layout: 'FW',
      header: 'Log in'
    }
  },
  {
    path: '/release-notes',
    name: 'FW-ReleaseNotes',
    component: () => import('@/pages/FW/ReleaseNotes.vue'),
    meta: { title: 'Release notes | Field Work', layout: 'FW', header: 'Field Work' }
  }
]
